import { Chip } from "@nextui-org/react"

const ErrorChip = (props) => {
  return <Chip 
    className={props.className || 'ml-2'}
    color={props.color || 'danger'}
    size='md'
    onClose={props.onClose ? onClose() : null}>
      {props.children}
  </Chip>
}

export default ErrorChip
